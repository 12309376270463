<div class="page page--public py-dynamic-large">
    @if (ready) {
        <header class="content content--center content--small mb-8">
            <h1 class="mb-0">{{page.titel}}</h1>
        </header>

        <article class="content--large content--center">
            <div class="article-intro content content--center content--small d-flex flex-column gap-8">
                <!-- <h2 class="mb-0">{{page.titel}}</h2> -->
                <span class="publish-date d-flex align-items-center">
                    <i class="icon thumbnail-logo"></i>
                    {{page.datum | formatTs : 'D MMMM YYYY'}}
                </span>
            </div>
    
            @if (page.afbeeldingURL) {
                <!-- <img [src]="[page.afbeeldingURL]" [alt]="page.titel" class="article-img radius-8"> -->
                <img class="article-img radius-8"
                    srcset="{{page.afbeeldingURL.mobile}} 960w, {{page.afbeeldingURL.tablet}} 1984w, {{page.afbeeldingURL.desktop}} 3600w" 
                    sizes="(max-width: 480px) 480px, (max-width: 992px) 992px, 1800px"
                    [src]="page.afbeeldingURL.desktop"
                    [alt]="page.titel"
                >
            }
    
            <div class="article-content  content content--small content--center">
                <div [innerHTML]="page.inhoud | safe : 'html'" class="richtext-print"></div>
            </div>
            
        </article>
    } @else {
        <app-spinner></app-spinner>
    }
</div>