import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, shareReplay, switchMap, tap } from 'rxjs';
import { ImageBannerComponent } from 'src/app/shared/components/banners/image-banner/image-banner.component';
import { DefaultService } from 'src/app/utils/api';
import { SafePipe, formatTsPipe } from '../../../../utils/pipes';
import { DefaultSearchObject } from 'src/app/shared/models/search.model';
import { InlineSpinnerComponent } from '../../../../shared/components/misc/inline-spinner/inline-spinner.component';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../../../shared/components/misc/button/button.component';
import { Newsitem } from 'backend/src/models/newsitem';

@Component({
    selector: 'app-news',
    standalone: true,
    imports: [ImageBannerComponent, SafePipe, formatTsPipe, InlineSpinnerComponent, RouterLink, ButtonComponent],
    templateUrl: './news.component.html',
    styleUrl: './news.component.scss'
})
export class NewsComponent {
    defaultService = inject(DefaultService);
    bannerHeight: number = 150;
    ready: boolean = false;
    loading: boolean = true;
    skeletonCount = Array(7);
    totalRows: number;

    search$ = new BehaviorSubject<DefaultSearchObject>({
        startrow: 0,
        RPP: 7,
        orderby: { code: 'sequence', dir: 'asc' }
    });

    news$ = this.search$.pipe(
        tap(() => {
            this.loading = true;
        }),
        switchMap((searchParams) =>
            this.defaultService
                .newsitemGetNewsitems({
                    startrow: searchParams.startrow,
                    orderby: [`${searchParams.orderby.code} ${searchParams.orderby.dir}`],
                    rpp: searchParams.RPP
                })
                .pipe(
                    tap((res) => {
                        this.totalRows = res.rows;
                    }),
                    map((res) => {
                        const mergedNewsitems: Newsitem[] = [...(this.news() ?? []), ...res.data];
                        return mergedNewsitems;
                        // return res.data;
                    }),
                    map((data) =>
                        data.map((x) => ({
                            ...x,
                            afbeeldingURL: {
                                desktop: x.afbeeldingURL.desktop ?? 'assets/img/misc/ivm-fallback-image.jpg',
                                tablet: x.afbeeldingURL.tablet,
                                mobile: x.afbeeldingURL.mobile
                            }
                        }))
                    )
                )
        ),
        tap(() => {
            this.loading = false;
            this.ready = true;
        }),
        shareReplay(1)
    );

    news = toSignal(this.news$, {
        initialValue: []
    });

    loadmore(newValues: Partial<DefaultSearchObject>) {
        this.search$.next({ ...this.search$.value, ...newValues });
    }
}
