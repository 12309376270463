import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { BehaviorSubject, combineLatest, lastValueFrom, map, shareReplay, switchMap, tap } from 'rxjs';
import { ImageBannerComponent } from 'src/app/shared/components/banners/image-banner/image-banner.component';
import { MedicineForm, MedicineFormComponent } from 'src/app/shared/components/medicine-form/medicine-form.component';
import { InlineSpinnerComponent } from 'src/app/shared/components/misc/inline-spinner/inline-spinner.component';
import {
    CreateNotificationPayload,
    DefaultService,
    MeldpuntNotification,
    NotificationUpdateNotificationBody,
    PageContent
} from 'src/app/utils/api';
import { formatTsPipe, SafePipe } from 'src/app/utils/pipes';
import { ButtonComponent } from '../../../../shared/components/misc/button/button.component';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { DefaultSearchObject } from 'src/app/shared/models/search.model';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        RouterModule,
        FormsModule,
        ImageBannerComponent,
        formatTsPipe,
        InlineSpinnerComponent,
        MedicineFormComponent,
        SafePipe,
        ButtonComponent,
        RouterLink,
        RouterOutlet
    ],

    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    animations: [
        trigger('slideUp', [
            transition('* <=> *', [
                query(
                    ':enter .medicine-detail-wrapper--mobile',
                    //slide up
                    [
                        style({
                            bottom: '-70%',
                            opacity: '.4'
                        }),
                        animate(
                            '300ms ease',
                            style({
                                bottom: 0,
                                opacity: 1
                            })
                        )
                    ],
                    { optional: true }
                ),
                query(
                    ':enter .medicine-detail-wrapper--desktop',
                    // appear
                    [
                        style({
                            top: '70%',
                            scale: '.8',
                            opacity: '.4'
                        }),
                        animate(
                            '300ms ease',
                            style({
                                top: '10%',
                                scale: 1,
                                opacity: 1
                            })
                        )
                    ],
                    { optional: true }
                ),
                group([
                    query(
                        ':leave .medicine-detail-wrapper--mobile',
                        // slide down
                        [
                            style({
                                bottom: 0,
                                scale: 1,
                                opacity: 1
                            }),
                            animate(
                                '300ms ease',
                                style({
                                    bottom: '-70%',
                                    opacity: '.4'
                                })
                            )
                        ],
                        { optional: true }
                    ),
                    query(
                        ':leave .medicine-detail-wrapper--desktop',
                        // disappear
                        [
                            style({
                                // top: '10%',
                                scale: '.8',
                                opacity: '.4'
                            }),
                            animate(
                                '300ms ease',
                                style({
                                    top: '70%',
                                    scale: '.8',
                                    opacity: 0
                                })
                            )
                        ],
                        { optional: true }
                    ),
                    query(
                        ':leave .overlay',
                        [
                            style({
                                scale: 1,
                                opacity: 1
                            }),
                            animate(
                                '120ms ease',
                                style({
                                    scale: '.6',
                                    opacity: '0'
                                })
                            )
                        ],
                        { optional: true }
                    )
                ])
            ])
        ])
    ]
})
export class HomeComponent implements OnInit {
    defaultService = inject(DefaultService);
    route = inject(ActivatedRoute);
    router = inject(Router);
    responsiveService = inject(ResponsiveService);

    screenSize = toSignal(this.responsiveService.onResize$, {
        initialValue: this.responsiveService.getScreenSize(window.innerWidth)
    });
    page: PageContent;
    loading: { page?: boolean; notifications: boolean; news: boolean } = {
        page: true,
        notifications: true,
        news: true
    };
    ready: boolean = false;
    submitting: boolean = false;

    bannerHeight: number = 150;

    FORM: MedicineForm = {
        medicijn: '',
        ervaringIndicator: undefined,
        ervaringDescr: undefined,
        meldingAangemaakt: false
    };

    validation: any = undefined;

    searchNotifications$ = new BehaviorSubject<DefaultSearchObject>({
        term: undefined,
        startrow: 0,
        RPP: 6,
        orderby: { code: 'createTS', dir: 'desc' }
    });

    recentNotificationsSkeletonCount = Array(6);
    recentNotifications$ = this.searchNotifications$.pipe(
        tap((res) => {
            this.loading.notifications = true;
        }),
        switchMap((searchParams) =>
            this.defaultService
                .notificationGetRecentNotifications({
                    startrow: searchParams.startrow,
                    orderby: [`${searchParams.orderby.code} ${searchParams.orderby.dir}`],
                    rpp: searchParams.RPP
                })
                .pipe(
                    map((res) => {
                        const mergedNotifications: MeldpuntNotification[] = [
                            ...(this.recentNotifications() ?? []),
                            ...res.data
                        ];

                        return mergedNotifications;
                    })
                )
        ),
        tap(() => {
            this.loading.notifications = false;
        }),
        shareReplay(1)
    );
    recentNotifications = toSignal(this.recentNotifications$, {
        initialValue: []
    });

    newsSkeletonCount = Array(4);
    news$ = this.defaultService
        .newsitemGetNewsitems({
            startrow: 0,
            rpp: 4,
            orderby: ['sequence asc'],
            homepage: true
        })
        .pipe(
            map((res) => res.data),
            map((data) =>
                data.map((x) => ({
                    ...x,
                    afbeeldingURL: x.afbeeldingURL ?? { desktop: 'assets/img/misc/ivm-fallback-image.jpg' }
                }))
            ),
            shareReplay(1)
        );

    news = toSignal(this.news$, {
        initialValue: []
    });

    constructor() {
        this.route.queryParams.pipe(map((params) => params?.medicijn)).subscribe((medicijn) => {
            if (medicijn) {
                this.FORM.medicijn = medicijn;
                this.scrollToTop('.medicine-form');
            }
        });

        combineLatest([this.recentNotifications$, this.news$]).subscribe((next) => {
            this.loading.notifications = false;
            this.loading.news = false;
            this.ready = true;
        });
    }

    async ngOnInit() {
        this.page = await lastValueFrom(this.defaultService.pageGetPageDetail({ slug: 'homepagina' }));
        this.loading.page = false;
    }

    updateSearchNotifications(newValues: Partial<DefaultSearchObject>) {
        this.searchNotifications$.next({ ...this.searchNotifications$.value, ...newValues });
    }

    prepareRoute(outlet: RouterOutlet) {
        const test = outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
        return test;
    }

    scrollToTop(identifier?: string) {
        (identifier ? document.querySelector(identifier) : document.body)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    submitNotification() {
        this.submitting = true;

        const payload: CreateNotificationPayload = {
            medicijn: this.FORM.medicijn,
            ervaringIndicator: this.FORM.ervaringIndicator,
            ervaringDescr: this.FORM.ervaringDescr
        };
        this.defaultService.notificationCreateNotification({ createNotificationPayload: payload }).subscribe({
            next: (next) => {
                this.FORM.meldingId = next.id;
                this.submitting = false;
                this.FORM.meldingAangemaakt = true;
                this.validation = undefined;
                this.scrollToTop('.medicine-form');
            },
            error: (error) => {
                this.submitting = false;
                this.validation = error.error.details;
                this.scrollToTop('.medicine-form');
            }
        });
    }

    submitAdditionalNotificationInfo() {
        this.submitting = true;

        const payload: NotificationUpdateNotificationBody = {
            id: this.FORM.meldingId,
            // slug: this.FORM.meldingId,
            overWerking: this.FORM.onderwerp?.includes('werking'),
            overBijwerkingen: this.FORM.onderwerp?.includes('bijwerkingen'),
            overPraktisch: this.FORM.onderwerp?.includes('praktisch'),
            leeftijd: this.FORM.leeftijd,
            geslacht: this.FORM.geslacht as NotificationUpdateNotificationBody.GeslachtEnum,
            redenGebruik: this.FORM.redenGebruik,
            ervaringNadien: this.FORM.ervaringNadien,
            hulpverlener: this.FORM.hulpverlener === 'yes' ? true : false,
            privacyPolicy: this.FORM.privacyPolicy
        };
        this.defaultService.notificationUpdateNotification({ notificationUpdateNotificationBody: payload }).subscribe({
            next: (next) => {
                this.submitting = false;
                this.FORM.meldingAangevuld = true;
                this.validation = undefined;
                this.scrollToTop('.medicine-form');
            },
            error: (error) => {
                this.submitting = false;
                this.validation = error.error.details;
                this.scrollToTop('.medicine-form');
            }
        });
    }
}
